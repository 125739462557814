// organize-imports-ignore
import 'es6-shim';
import 'es6-weak-map/implement';
import 'raf/polyfill';
import 'core-js/actual/array/includes';
import 'core-js/actual/array-buffer/is-view';
import 'core-js/actual/object/entries';
import 'core-js/actual/object/from-entries';
import 'core-js/actual/object/values';
import 'core-js/es/symbol';
import 'core-js/es/set';
import 'url-search-params-polyfill';
import { auto } from 'browser-unhandled-rejection';
import { initAppManager } from './appManager';
import { initDevShortKeys, initTvScreenConsoleLogger } from './initDeveloperTools';
import { initSentry } from './trackError';
import { id } from './utils/crypto';
import { StorageKey } from '../app/context/StorageKeyEnums';

auto(); // Applies polyfill if necessary to window.Promise

// TODO: Remove if we dont need dash parser: From useManifestParser: rx-player/dist/_esm5.processed/parsers/manifest/dash/js-parser
const performancePolyfill = window.performance || {};
if (typeof performancePolyfill !== 'undefined' && typeof performancePolyfill.now !== 'function') {
  performancePolyfill.now = (function one(): any {
    const _now = Date.now();
    return (
      performancePolyfill.now ||
      // @ts-ignore
      performancePolyfill.webkitNow ||
      function two(): any {
        // Makes Panasonic TV great again. Never log on performance.now!!!!!!!
        // console.warn('Use window.performance.now fallback');
        return Date.now() - _now;
      }
    );
  })();
}

// Ifall browsern inte har performance -> polyfill
if (typeof window.performance === 'undefined') {
  // @ts-ignore
  window.performance = performancePolyfill;
}

const { svtBoot } = window;

const canRunApplication = (): boolean => {
  return true;
};

function initGlobals(): void {
  svtBoot.sessionId = id();

  window.svtBoot.isAbroad = false;

  try {
    const localStorageDeviceId = window.localStorage.getItem(StorageKey.DeviceId);
    if (!localStorageDeviceId) {
      const newDeviceId = id();
      window.localStorage.setItem(StorageKey.DeviceId, newDeviceId);
      window.svtBoot.deviceId = newDeviceId;
    } else {
      window.svtBoot.deviceId = localStorageDeviceId;
    }
  } catch (error) {
    console.log('could not set DeviceId');
  }
}

function setCurrentProfileFromQueryParams() {
  // Set current profile to be able to go direct to a page
  try {
    if (window.location.search.indexOf('currentProfileId=0') > -1) {
      console.warn('Overide setting currentProfileId to 0');
      window.localStorage.setItem('svt:hbbtv:currentProfileId', '0');
    } else if (window.location.search.indexOf('currentProfileId=1') > -1) {
      console.warn('Overide setting currentProfileId to 1');
      window.localStorage.setItem('svt:hbbtv:currentProfileId', '1');
    }
  } catch (error) {
    console.log(error);
  }
}

function initializeApplication(isDebugEnabled: boolean): void {
  initGlobals();
  initSentry(isDebugEnabled);
  initTvScreenConsoleLogger(isDebugEnabled);
  // try {
  //   console.warn('performance now: ', window.performance.now());

  //   // @ts-ignore
  //   console.warn('performance webkitnow: ', window.performance.webkitNow());
  // } catch (e) {
  //   console.log('could not performance now');
  // }
  initDevShortKeys();
  initAppManager();
  try {
    const key = 'svt:hbbtv:calendarOpenedFlaps22:1';
    if (window.localStorage[key]) {
      window.localStorage.removeItem(key);
    }
  } catch (error) {
    console.log(error);
  }

  setCurrentProfileFromQueryParams();

  if (canRunApplication()) {
    import(/* webpackChunkName: 'main' */ '../main');
  }
}

function initializeBoot(): void {
  const isDebugEnabled = svtBoot.buildVars.env === 'development' || process.env.DEBUG === 'true';

  console.log('initializeBoot called');
  const style =
    'background-color: #0d3c35; color: #ffdc8c; font-size: 1em; border: 5px solid #0d3c35;';
  console.log(
    `%cApp version: ${svtBoot.buildVars.version} | Git commit: ${svtBoot.buildVars.gitVersion}`,
    style
  );

  initializeApplication(isDebugEnabled);
}

initializeBoot();
