// Important!
// Due to issues with LG's localStorage implementation we need to use bracket notation
// when writing to localStorage. We should also avoid unnecessary write operations.
import { reportMessage } from './reportError';
import { StorageKey } from '../context/StorageKeyEnums';
import { IVideoProgressStore } from '../context/StorageTypes';

export const getItem = <T>(key: StorageKey): T | null => {
  const stringifiedValue = window.localStorage[key];
  try {
    if (stringifiedValue) {
      return JSON.parse(stringifiedValue) as T;
    }
    return null;
  } catch (error) {
    // Due to a bug which caused the videoProgress to sometimes be corrupt we clear it if we cannot parse it.
    if (key.startsWith(StorageKey.VideoProgress)) {
      console.log('storage.ts --> ERROR ERROR! Clearing VideoProgress');
      window.localStorage[key] = '{}';
    }

    const extra = { error, key };
    reportMessage('Failed to getItem from localStorage', '', extra);
    return null;
  }
};

export const getItemWithProfile = <T>(key: StorageKey, profileId: string): T | null => {
  const profileKey = `${key}:${profileId}`;

  return getItem(profileKey as StorageKey);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setItem = (key: StorageKey, value: any): void => {
  try {
    const previousStringifiedValue = window.localStorage[key];
    const stringifiedValue = JSON.stringify(value);
    if (previousStringifiedValue !== stringifiedValue) {
      window.localStorage[key] = stringifiedValue;
    }
  } catch (error) {
    console.log(`Failed to setItem in localStorage for ${key}: `, error);
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setItemWithProfile = (key: StorageKey, value: any, profileId: string): void => {
  const profileKey = `${key}:${profileId}`;

  return setItem(profileKey as StorageKey, value);
};

export const getVideoHistoryLength = (): { nrOfVPItem: number } => {
  try {
    const videoProgress = getItemWithProfile<IVideoProgressStore>(StorageKey.VideoProgress, '0');
    if (videoProgress) {
      const nrOfVPItem = Object.keys(videoProgress).length;
      return { nrOfVPItem };
    }
  } catch (error) {
    console.log('getVideoHistoryLength', error);
  }
  return { nrOfVPItem: -1 };
};

const getLegacyVideoHistoryLength = (): { nrOfVPItem: number } => {
  try {
    const videoProgress = getItem<IVideoProgressStore>(StorageKey.VideoProgress);
    if (videoProgress) {
      const nrOfVPItem = Object.keys(videoProgress).length;
      return { nrOfVPItem };
    }
  } catch (error) {
    console.log('getVideoHistoryLength', error);
  }
  return { nrOfVPItem: -1 };
};

export function getStorageSize(): {
  localStorageSize: string;
  sessionStorageSize: string;
  nrOfVPItem: number;
} {
  let localStorageSize = -1;
  let sessionStorageSize = -1;
  try {
    localStorageSize = JSON.stringify(window.localStorage).length;
    sessionStorageSize = JSON.stringify(window.sessionStorage).length;
  } catch (error) {
    console.log('Failed to get localStorage and sessionStorage size', error);
  }
  const storageSizeObject = {
    localStorageSize: `${(localStorageSize / 1024).toFixed(2)} kb`,
    sessionStorageSize: `${(sessionStorageSize / 1024).toFixed(2)} kb`,
    ...getVideoHistoryLength(),
    legacyNrOfVPItem: getLegacyVideoHistoryLength().nrOfVPItem,
  };

  console.log('getStorageSize -> storageSizeObject: ', storageSizeObject);
  return storageSizeObject;
}

// @TODO Would it not be tha same to just return localStorage here?
export function allStorage(): (string | null)[] {
  const archive: any = {};
  const storage = window.localStorage || {};
  const keys = Object.keys(storage);
  let i = keys.length;

  // eslint-disable-next-line no-plusplus
  while (i--) {
    if (keys[i] !== 'data-sdk-client-id' && keys[i] !== StorageKey.Accounts) {
      archive[keys[i]] = window.localStorage[keys[i]];
    }
  }

  return archive;
}

export function compressedStorage(): (string | null)[] {
  const archive: any = {};
  const storage = window.localStorage || {};
  const compressKeys = [
    `${StorageKey.Favorites}:0`,
    `${StorageKey.Favorites}:1`,
    `${StorageKey.VideoProgress}:0`,
    `${StorageKey.VideoProgress}:1`,
    `${StorageKey.SearchHistory}:0`,
    `${StorageKey.SearchHistory}:1`,
  ];
  const keys = Object.keys(storage).filter((key) => compressKeys.indexOf(key) === -1);
  let i = keys.length;

  // eslint-disable-next-line no-plusplus
  while (i--) {
    if (keys[i] !== 'data-sdk-client-id' && keys[i] !== StorageKey.Accounts) {
      archive[keys[i]] = window.localStorage[keys[i]];
    }
  }
  return archive;
}
